import React from 'react';

import { useMsal } from '@azure/msal-react';

import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const MENU_ID = 'profile-menu';

const Header = () => {
  const [profileEl, setProfileEl] = React.useState<HTMLElement>();
  const { accounts, instance } = useMsal();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setProfileEl(undefined);
  };

  const handleSignOut = () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: import.meta.env.VITE_AZURE_AD_REDIRECT_URI,
      onRedirectNavigate: () => false,
    };
    instance.logoutRedirect(logoutRequest);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component="img"
            alt="Eval.com Logo"
            src="/evalLogoWhite.svg"
            sx={{ height: 30 }}
          />
          <Typography variant="h6" component="div">
            Property Search
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="user profile"
              aria-controls={MENU_ID}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
            >
              <AccountCircle data-testid="account-icon" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={profileEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={MENU_ID}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(profileEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default Header;
