import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';

import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { lazy, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';
import { httpBatchLink, loggerLink } from '@trpc/client';
import superJSON from 'superjson';

import Header from './Header';
import Login from './Login';
import AuthService from '../services/auth';
import { trpc } from '../trpc';
import theme from './theme';
import './app.css';

const AppRouter = lazy(() => import('./AppRouter'));

const App = () => {
  const queryClientOptions: QueryClientConfig = {
    defaultOptions: {
      mutations: {
        onError: (error: unknown) => Sentry.captureException(error),
      },
      queries: {
        refetchOnWindowFocus: false,
        onError: (error: unknown) => Sentry.captureException(error),
      },
    },
  };
  const [queryClient] = useState(() => new QueryClient(queryClientOptions));
  const msal = useMsal();
  const authService = useMemo(() => new AuthService(msal), [msal]);
  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        transformer: superJSON,
        links: [
          loggerLink({
            enabled: (opts) =>
              import.meta.env.VITE_NODE_ENV !== 'production' ||
              (opts.direction === 'down' && opts.result instanceof Error),
          }),
          httpBatchLink({
            url: `${import.meta.env.VITE_API_URL}/trpc`,
            headers: async () => {
              const { accessToken } = await authService.getAccessToken();

              return {
                authorization: `Bearer ${accessToken}`,
              };
            },
          }),
        ],
      }),
    [authService],
  );

  useEffect(() => {
    Sentry.setUser({ username: msal.accounts[0]?.username.toLowerCase() });
  }, [msal.accounts]);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Box sx={{ display: 'flex', height: '100vh', paddingTop: 8 }}>
                <Header />
                <AppRouter />
              </Box>
            </AuthenticatedTemplate>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default App;
