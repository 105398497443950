import { blue, grey, orange, purple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      palette: {
        propertySources: {
          both: string;
          mls: string;
          pubRec: string;
        };
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      palette?: {
        propertySources?: {
          both?: string;
          mls?: string;
          pubRec?: string;
        };
      };
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: blue[800],
    },
  },
  custom: {
    palette: {
      propertySources: {
        both: orange[100],
        mls: purple[100],
        pubRec: blue[100],
      },
    },
  },
});

export default theme;
