import { useState } from 'react';

import { useMsal } from '@azure/msal-react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import lottie from 'lottie-web';

import useAuthEvents from '../../hooks/useAuthEvents';

const Wrapper = styled(Box)({
  height: '100vh',
});

const CenteredGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const LoginAnimation = () => {
  const [animationInit, setAnimationInit] = useState(false);

  const initLoginAnimation = (el: HTMLDivElement | null) => {
    if (!animationInit) {
      const animation = lottie.loadAnimation({
        container: el!,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/lottie/login.json',
      });

      animation.setSpeed(0.8);
      setAnimationInit(true);
    }
  };

  return (
    <CenteredGrid
      item
      xs={6}
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        height: '100vh',
      })}
    >
      <Box id="login-animation" ref={initLoginAnimation} />
    </CenteredGrid>
  );
};

const SignInWithMicrosoft = () => {
  const { instance } = useMsal();
  const onSignIn = () => instance.loginRedirect();

  return (
    <Box
      component="img"
      alt="Sign in with Microsoft"
      src="/microsoftSignIn.svg"
      sx={{ cursor: 'pointer' }}
      onClick={onSignIn}
    />
  );
};

const CopyAndSignIn = () => {
  const { error: authError } = useAuthEvents();

  return (
    <CenteredGrid
      item
      xs={6}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[200],
        height: '100vh',
      })}
    >
      <Paper
        elevation={6}
        component={Box}
        paddingY={8}
        paddingX={10}
        sx={(theme) => ({
          backgroundColor: 'white',
          [theme.breakpoints.up('lg')]: {
            minWidth: 500,
          },
        })}
      >
        <Box
          component="img"
          alt="Eval.com logo"
          src="/evalLogo.svg"
          sx={{ marginBottom: '80px', height: 24 }}
        />
        <Typography variant="h4">
          Sign into your
          <br />
          account.
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 7, marginTop: 2 }}>
          Find subjects and comparables.
        </Typography>
        <SignInWithMicrosoft />
        {authError && (
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            {authError}
          </Typography>
        )}
      </Paper>
    </CenteredGrid>
  );
};

const Login = () => (
  <Wrapper>
    <CenteredGrid container>
      <LoginAnimation />
      <CopyAndSignIn />
    </CenteredGrid>
  </Wrapper>
);

export default Login;
