import { useEffect, useState } from 'react';

import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

const useAuthEvents = () => {
  const [error, setError] = useState<string | undefined>();
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      (message: { eventType: EventType; errorMessage?: string }) => {
        if (message.eventType === EventType.LOGIN_FAILURE) {
          setError(message.errorMessage);
        } else {
          setError(undefined);
        }
      },
    );

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return { error };
};

export default useAuthEvents;
