import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import {
  BrowserCacheLocation,
  Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import App from './App';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_SENTRY_RELEASE,
});

const config: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_AD_UI_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${
      import.meta.env.VITE_AZURE_AD_TENANT_ID
    }`,
    redirectUri: import.meta.env.VITE_AZURE_AD_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

const publicClientApplication = new PublicClientApplication(config);
const ErrorPage = lazy(() => import('./App/Error'));

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <Suspense>
          <ErrorPage />
        </Suspense>
      }
    >
      <MsalProvider instance={publicClientApplication}>
        <App />
      </MsalProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
