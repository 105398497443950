import type { IMsalContext } from '@azure/msal-react';

class AuthService {
  msalInstance: IMsalContext;

  constructor(msalInstance: IMsalContext) {
    this.msalInstance = msalInstance;
  }

  async getAccessToken() {
    try {
      const { accessToken } =
        await this.msalInstance.instance.acquireTokenSilent(
          this.getAccessTokenRequest(),
        );
      return {
        accessToken,
      };
    } catch (error) {
      return { error };
    }
  }

  getAccessTokenRequest() {
    return {
      scopes: [
        `api://${import.meta.env.VITE_AZURE_AD_API_CLIENT_ID}/${
          import.meta.env.VITE_AZURE_AD_SCOPE
        }`,
      ],
      account: this.msalInstance.accounts[0],
    };
  }
}

export default AuthService;
